<template>
	<div class="page">
		<webHeader :currentIndex="currentIndex"></webHeader>
		<div class="centerMain">
			<navLeft :navList="navList" @exchange="exchange" :title="$t('webHeader.personCenter')"></navLeft>
			<div class="mainPage">
				<!--身份验证-->
				<div class="verification" v-if="isDisplay">
					<van-row class="identity">
					  <van-cell :title="$t('sfrz.gjrz')" center @click.prevent="toadvanced" class="pointer">
					    <template #default>
					      <span v-if="oauth < 3">{{ $t('sfrz.auth0') }}</span>
					      <span v-if="oauth == 3">{{ $t('sfrz.auth3') }}</span>
					      <span v-if="oauth == 4">{{ $t('sfrz.auth4') }}</span>
					    </template>
					  </van-cell>
					  <van-row class="identity-notice">
					    <!-- <span>{{ $t('sfrz.text3') }}</span> -->
					    <span>{{ $t('sfrz.text6') }}</span>
					    <span>
					      {{ $t('sfrz.text5') }}
					    </span>
					  </van-row>
					</van-row>
				</div>
				<div class="verificationForm" v-if="!isDisplay">
					<div class="title">
						<div class="nav" @click="back()">{{$t('webTitle.back')}}</div>
					</div>
					<van-row class="spacing">
					        <van-form ref="form">
					          <van-row class="field">
					            <van-field
					              v-model="identity.profession"
					              :label="$t('gjrz.jobs')"
					              :placeholder="$t('gjrz.notice1')"
					              :rules="[{ required: true, message: $t('common.required') }]"
					            />
					          </van-row>
					          <van-row class="field">
					            <van-field
					              v-model="identity.income"
					              :label="$t('gjrz.income')"
					              :placeholder="$t('gjrz.notice2')"
					              :rules="[{ required: true, message: $t('common.required') }]"
					            />
					          </van-row>
					          <van-row class="field">
					            <van-field
					              v-model="identity.contact_number"
					              :label="$t('gjrz.phone')"
					              :placeholder="$t('gjrz.notice3')"
					              :rules="[{ required: true, message: $t('common.required') }]"
					            />
					          </van-row>
					
					          <van-row style="margin-top: 2rem;" class="message">{{ $t('gjrz.sfzzm') }}</van-row>
					          <van-row class="uploading-image">
					            <van-uploader :beforeRead="beforeRead" :after-read="aftersfza">
					              <img
					                :src="sfza ? sfza : ''"
					                ref="sfza"
					                v-if="sfza"
					                style="max-width:100%;max-height:10rem;text-align:center"
					              />
					            </van-uploader>
					          </van-row>
					          <van-row class="message">{{ $t('gjrz.sfzfm') }}</van-row>
					          <van-row class="uploading-image">
					            <van-uploader :beforeRead="beforeRead" :after-read="aftersfzb">
					              <img
					                :src="sfzb ? sfzb : ''"
					                ref="sfzb"
					                v-if="sfzb"
					                style="max-width:100%;max-height:10rem;text-align:center"
					              />
					            </van-uploader>
					          </van-row>
					<!--          <van-row class="message">{{ $t('gjrz.sczj') }}</van-row>
					          <van-row class="uploading-image">
					            <van-uploader :beforeRead="beforeRead" :after-read="aftersczj">
					              <img
					                :src="sczj ? sczj : ''"
					                ref="sczj"
					                v-if="sczj"
					                style="max-width:100%;max-height:10rem;text-align:center"
					              />
					            </van-uploader>
					          </van-row> -->
					<!--          <van-row class="message">{{ $t('gjrz.qtzm') }}</van-row>
					          <van-row class="uploading-image">
					            <van-uploader :beforeRead="beforeRead" :after-read="afterqtzm">
					              <img
					                :src="qtzm ? qtzm : ''"
					                ref="qtzm"
					                v-if="qtzm"
					                style="max-width:100%;max-height:10rem;text-align:center"
					              />
					            </van-uploader>
					          </van-row> -->
					        </van-form>
					        <van-button
					          v-if="identity.oauth === '2'"
					          class="submitBtn"
					          @click.prevent="submit"
					          >{{ $t('common.submit') }}</van-button
					        >
					        <van-button v-if="identity.oauth === '3'" class="submitBtn">{{
					          $t('gjrz.auth1')
					        }}</van-button>
					        <van-button v-if="identity.oauth === '4'" class="submitBtn">{{
					          $t('gjrz.auth2')
					        }}</van-button>
					      </van-row>
				</div>
			</div>
		</div>
		<webFooter></webFooter>
	</div>
</template>

<script>
	import webHeader from "@/components/web/webHeader.vue";
	import navLeft from "@/components/web/navLeft.vue";
	import webFooter from "@/components/web/webFooter.vue";
	export default {
	    components: { webHeader, navLeft, webFooter },
	    data() {
			return{
				currentIndex:5,
				navList:[
					{
						icon:require('../../../assets/img/web/iconAuth.png'),
						name:this.$t('home.gj_auth'),
						path:'/web/my/index'
					},
					{
						icon:require('../../../assets/img/web/iconMessage.png'),
						name:this.$t('home.message'),
						path:'/web/my/Contact_list'
					},
					// {
					// 	icon:require('../../../assets/img/serve_my.svg'),
					// 	name:this.$t('home.serverurl'),
					// 	path:this.serverurl,
					// },
					{
						icon:require('../../../assets/img/web/iconSetting.png'),
						name:this.$t('home.set'),
						path:'/web/my/setting',
					},
				],
				user: {},
				serverurl: "",
				mUserName: "",
				oauth: '',
				isDisplay:true,
				identity: {},
				rules: [],
				sfza: '',
				sfzb: ''
				// sczj: '',
				// qtzm: ''
			}
		},
		created() {
			this.getdata();
			this.getoauth();
			this.getinfo();
		},
		methods:{
			// 获取首页数据
			async getdata() {
			  const { data } = await this.$http.get("/home/home/index");
			  if (data) {
			    if (data.code === 200) {
			      this.user = data.data.user;
			      this.mUserName = data.data.user.username;
			      this.serverurl = data.data.serverurl;
			    }
			  }
			},
			async getoauth() {
			  const { data } = await this.$http.get('/home/home/getoauth')
			  if (data) {
			    if (data.code === 200) {
			      this.oauth = data.data
			    }
			  }
			},
			// 返回
			toadvanced() {
				this.isDisplay = false;
			  //this.$router.push('/person/advanced')
			},
			async getinfo() {
			  const { data } = await this.$http.get('/home/home/getindentity')
			  if (data) {
			    if (data.code === 200) {
			      this.identity = data.data
			      if (parseInt(data.data.oauth) < 2) {
			        this.$router.push('/person/authentication')
			      }
			    }
			  }
			},
			beforeRead(file) {
			  if (
			    file.type !== 'image/jpeg' &&
			    file.type !== 'image/png' &&
			    file.type !== 'image/gif'
			  ) {
			    this.$toast(this.$t('gjrz.toast'))
			    return false
			  }
			  return true
			},
			async aftersfza(file) {
			  const param = new FormData()
			  param.append('file', file.file)
			  const { data } = await this.$http.post('/home/user/uploadimg', param)
			  if (data) {
			    this.sfza = data.data.url
			  }
			},
			async aftersfzb(file) {
			  const param = new FormData()
			  param.append('file', file.file)
			  const { data } = await this.$http.post('/home/user/uploadimg', param)
			  if (data) {
			    this.sfzb = data.data.url
			  }
			},
			// async aftersczj(file) {
			//   const param = new FormData()
			//   param.append('file', file.file)
			//   const { data } = await this.$http.post('/home/user/uploadimg', param)
			//   if (data) {
			//     this.sczj = data.data.url
			//   }
			// },
			// async afterqtzm(file) {
			//   const param = new FormData()
			//   param.append('file', file.file)
			//   const { data } = await this.$http.post('/home/user/uploadimg', param)
			//   if (data) {
			//     this.qtzm = data.data.url
			//   }
			// },
			// 提交认证
			submit() {
			  this.$refs.form
			    .validate()
			    .then(async () => {
			      const { data } = await this.$http.post('/home/home/advanced', {
			        profession: this.identity.profession,
			        income: this.identity.income,
			        contact_number: this.identity.contact_number,
			        sfza: this.sfza,
			        sfzb: this.sfzb
			        // sczj: this.sczj,
			        // qtzm: this.qtzm
			      })
			      if (data) {
			        if (data.code === 200) {
			          this.$toast.success(this.$t('common.success'))
			          setTimeout(() => {
			            this.$router.push('/index')
			          }, 300)
			        } else {
			          this.$toast.fail(
			            this.getlang(data.msg, localStorage.getItem('lang'))
			          )
			        }
			      }
			    })
			    .catch(() => {
			      return false
			    })
			},
			back(){
				this.isDisplay = true;
			},
			exchange(data){

				
			}
		}
	}
</script>

<style lang="less" scoped>
	.page{ background-color: #141416; min-height: 100vh;
		.centerMain{ padding:20px 0; width:1200px; margin:0 auto; display: flex; justify-content: space-between;
			.mainPage{ min-height: calc(100vh - 80px);background-color: #18191d; color:#fff; width:1000px; text-align: left;}
		}
	}
	.verification{
		.identity {
		  width: 90%;
		  height: 13.33333rem;
		  margin-left: 5%;
		  margin-top: 4%;
		  border-radius: 0.53333rem;
		  .van-cell {
		    font-size: 1rem;
		    color: #000 !important;
		    border-radius: 0.53333rem;
		    .van-cell__title {
		      text-align: left;
		    }
		    .van-cell:after {
		      border-bottom: 0.02667rem solid #e5e5e5;
		    }
		  }
		  .identity-notice span {
		    color: #b2b2b2;
		    display: block;
		    font-size: 0.98667rem;
		    text-align: left;
		    margin: 1.33333rem 0 0 1.33333rem;
		  }
		}
	}
	.verificationForm{ padding:20px;
		.title{ padding-bottom: 10px; border-bottom:1px solid #ccc;
			.nav{ cursor: pointer; display: inline-block; padding:5px 10px; border:1px solid #fff; border-radius: 5px; margin-right: 10px;}
			.titText{ display: inline-block; font-size: 18px; font-weight: 600;}
		}
		.spacing {
		  margin-top: 1rem;
		  .field {
		    background-color: #f1f1f2;
		    margin-top: 0.53333rem;
		    width: 90%;
		    margin-left: 5%;
		    border-radius: 0.53333rem;
		    span {
		      color: #000;
		      font-size: 1.86667rem;
		    }
		    .van-cell {
		      font-size: 0.98667rem;
		      background-color: #f1f1f2 !important;
		      color: #fff !important;
		    }
		    .van-field__control {
		      padding-right: 1.2rem;
		    }
		    /deep/.van-field input {
		      color: #000;
		      text-align: left;
		    }
		    /deep/.van-field__error-message {
		      text-align: center;
		    }
		  }
		  .submitBtn {
		    margin-top: 10px;
		    width: 100%;
		    letter-spacing: 0.53333rem;
		    height: 4rem;
		    font-size: 0.93333rem;
		    color: #fff;
		    background-color: #004ea3;
		    border-radius: 0.53333rem;
		    border: none;
		    box-shadow: 0 0 1.33333rem #004ea3;
		  }
		}
		.uploading-image {
		  height: 10rem;
		  width: 80%;
		  margin: 2% 10% 5% 10%;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  border: 0.02667rem dashed grey;
		  .van-uploader {
		    width: 100%;
		    height: 100%;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    /deep/.van-uploader__wrapper {
		      width: 100%;
		      height: 100%;
		      justify-content: center;
		    }
		    /deep/.van-uploader__upload {
		      width: 100%;
		      height: 100%;
		      margin: 0;
		      background-color: #fff;
		      color: #fff;
		    }
		  }
		}
	}
</style>